@import './variables.css';

.App {
  font-family: sans-serif;
  text-align: center;
}

body {
  padding-top: 60px;
  /* Adjust this value to match the height of your navbar */
  margin: 0;
  font-family: Arial, sans-serif;
}

/* Additional styles for other components */
.content {
  padding: 5px;
}

.custom-data-table {
  font-size: 18px;
}

.table-responsive td,
.table-responsive th {
  white-space: normal;
  word-wrap: break-word;
}


.custom-data-table th,
.custom-data-table td {
  font-size: 20px;
}

.custom-data-table th:first-child {
  width: 75px;
}

.custom-data-table th:last-child {
  width: 100px;
}

/* ################################################################################# */
/* nav bar css */

.eh-navbar {
  background: linear-gradient(90deg, var(--primary-color), var(--secondary-color), var(--accent-color));
  /* Gradient background */
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  /* Ensure space between logo and icons */
  align-items: center;
  /* Center items vertically */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for depth */
  position: fixed;
  /* Stick to the top */
  width: 100%;
  top: 0;
  z-index: 1000;
  /* Ensure it stays above other elements */
}

/* Ensure navbar container styles */
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  /* Center the navbar container */
}

/* Logo styles */
.navbar-logo img {
  height: 40px;
  /* Keep the height fixed */
  width: auto;
  transform: scale(3);
  /* Increase the size of the logo */
  transform-origin: center;
  /* Ensure scaling is centered */
}

/* Menu styles */
.navbar-menu {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-grow: 1;
  /* Allow the menu to grow and push the icons to the right */
}

/* Menu item styles */
.navbar-item {
  color: var(--light-text-color);
  /* White text color */
  text-decoration: none;
  font-size: 16px;
  padding: 8px 12px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Menu item hover styles */
.navbar-item:hover {
  background-color: var(--hover-background-color);
  /* Light hover effect */
  color: var(--light-text-color);
  /* White text color on hover */
}

/* Icons styles */
.navbar-icons {
  display: flex;
  gap: 20px;
}

/* Icon styles */
.navbar-icon {
  color: var(--light-text-color);
  /* White icon color */
  font-size: 20px;
  cursor: pointer;
  transition: color 0.3s ease;
}

/* Icon hover styles */
.navbar-icon:hover {
  color: var(--hover-icon-color);
  /* Light hover effect */
}

/* button.css */

.btn-view {
  display: inline-block;
  padding: 8px 16px;
  background-color: var(--primary-color);
  /* Use primary color for background */
  color: var(--light-text-color);
  /* Use light text color */
  border: none;
  border-radius: 5px;
  /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  /* Smooth transition on hover */
}

/* Hover effect */
.btn-view:hover {
  background-color: var(--secondary-color);
  /* Use secondary color on hover */
  transform: scale(1.05);
}

.btn-view:active {
  background-color: var(--accent-color);
  /* Use accent color when clicked */
  transform: scale(0.95);
  /* Scale down effect when clicked */
}


/* for containers */
.scrollable-columns-container {
  overflow-x: auto;
}

.scrollable-columns {
  display: flex;
}

.loadingBar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}


/* workflow page */
/* ################################################################################# */

.workflows-container {
  display: flex;
  justify-content: center;
  position: relative;
  top: 6rem;
}

.data-processing-container {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  border: 1px solid var(--secondary-color);
  /* Use secondary color */
  border-radius: 8px;
  background-color: var(--light-background-color);
  /* Use light background color */
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  align-items: center;
  height: auto;
  /* Ensure the container doesn't have a fixed height */
}

.workflows-h1 {
  text-align: center;
  color: var(--dark-text-color);
  /* Use dark text color */
}

.workflows-form {
  display: flex;
  flex-direction: column;
}

.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.input-field {
  flex: 1;
  padding: 8px;
  border: 1px solid var(--secondary-color);
  /* Use secondary color */
  border-radius: 4px;
  font-size: 14px;
}

.remove-button {
  margin-left: 10px;
  padding: 8px;
  border: none;
  border-radius: 4px;
  background-color: #ff6b6b;
  /* Red background color */
  color: var(--light-text-color);
  /* Use light text color */
  cursor: pointer;
  font-size: 14px;
}

.remove-button:hover {
  background-color: #ff4b4b;
  /* Darker red on hover */
}

.add-button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background-color: var(--primary-color);
  /* Use primary color */
  color: var(--light-text-color);
  /* Use light text color */
  cursor: pointer;
  align-self: flex-start;
  margin-bottom: 10px;
  /* Add spacing between + and Submit button */
  font-size: 14px;
}

.add-button:hover {
  background-color: var(--secondary-color);
  /* Use secondary color on hover */
}

.submit-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #28a745;
  /* Green color */
  color: var(--light-text-color);
  /* Use light text color */
  cursor: pointer;
  font-size: 14px;
}

.submit-button:hover {
  background-color: #218838;
  /* Darker green on hover */
}

.submit-button:disabled {
  background-color: #95a5a6;
}


.status-message {
  text-align: center;
  margin-top: 20px;
  color: var(--dark-text-color);
  /* Use dark text color */
  font-size: 14px;
}

.download-link {
  display: block;
  text-align: center;
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: var(--accent-color);
  /* Use accent color */
  color: var(--light-text-color);
  /* Use light text color */
  text-decoration: none;
  font-size: 14px;
}

.download-link:hover {
  background-color: #fa8231;
  /* Darker accent color on hover */
}

/* ################################################################################## */
/* home page */
.filter-container {
  margin-left: 20px;
  display: flex;
  gap: 20px;
  padding: 10px 0;
}

.filter-item {
  display: flex;
  flex-direction: column;
  position: relative;
}

.filter-label {
  margin-bottom: 5px;
  font-weight: bold;
}

.booking-software-select {
  width: 150px;
  /* Adjust the width as per your need */
  padding: 5px;
  font-size: 16px;
  /* You can change the font size if needed */
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-right: 10px;
}

.dropdown-select {
  width: 200px;
}

.date-picker-container {
  display: flex;
  gap: 10px;
}

.date-picker {
  width: 120px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.clear-btn {
  font-size: 17px;
  font-weight: bold;
  text-decoration: underline;
  color: #000;
  cursor: pointer;
  margin-bottom: 10px;
  /* Space between 'Clear' and the filters */
  align-self: flex-end;
  /* Align the 'Clear' to the right */
}

.clear-btn:hover {
  color: var(--accent-color);
  text-decoration: none;
}

/* Container for the download button */
.download-btn-container {
  display: flex;
  align-items: center;
  margin-left: auto;
  /* Pushes the button to the right */
  margin-right: 20px;
  margin-top: 20px;
}

/* Button styling */
.download-btn {
  background: #c9c9c9;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: var(--light-text-color);
}

/* Button hover effect */
.download-btn:hover {
  background: var(--secondary-color);
}

/* Clear and position the download icon */
.download-btn svg {
  color: var(--light-text-color);
}

/* ############################################################### */

.location-table-container {
  margin: 20px;
  border: 2px solid var(--secondary-color);
  /* Use secondary color */
  border-radius: 8px;
  /* Slightly smaller border radius */
  padding: 20px;
  /* Increased padding for better spacing */
  background-color: var(--light-background-color);
  /* Light background for the container */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for depth */
}

.table-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.table-header {
  font-size: 30px;
  font-weight: bold;
  color: var(--dark-text-color);
  /* Use dark text color */
}

.column-selection {
  display: flex;
  justify-content: flex-end;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: var(--light-background-color);
  color: var(--dark-text-color);
  padding: 8px;
  font-size: 14px;
  border: 1px solid var(--secondary-color);
  cursor: pointer;
  border-radius: 5px;
}

.arrow-down {
  font-size: 12px;
  margin-left: 5px;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  /* Align to the right side */
  background-color: var(--light-background-color);
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 250px;
  /* Adjusted height */
  overflow-y: auto;
  border-radius: 5px;
  border: 1px solid var(--secondary-color);
}

.dropdown-content div {
  padding: 8px 16px;
  display: flex;
  align-items: center;
}

.dropdown-content div input {
  margin-right: 10px;
}

.dropdown-content div:hover {
  background-color: var(--hover-background-color);
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: var(--hover-background-color);
}

/* ################################################################################# */
/* Toast notification */

.toast {
  padding: 10px 20px;
  border-radius: 5px;
  margin: 10px 0;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
}

.toast-info {
  background-color: #3498db;
}

.toast-success {
  background-color: #2ecc71;
}

.toast-warning {
  background-color: #f39c12;
}

.toast-error {
  background-color: #e74c3c;
}

.toast-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.toast-message {
  font-size: 14px;
}

/* ################################################################################# */
/* login-page.css */

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Take full screen height */
  background-color: var(--light-background-color);
}

.login-container {
  background-color: white;
  border: 2px solid var(--secondary-color);
  /* Border with secondary color */
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 350px;
  max-width: 100%;
}

.login-logo {
  width: 120px;
  margin-bottom: 20px;
}

.login-title {
  color: var(--dark-text-color);
  /* Use dark text color */
  font-size: 24px;
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 15px;
  text-align: left;
}

.input-group label {
  display: block;
  font-size: 14px;
  color: var(--dark-text-color);
  margin-bottom: 5px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.2s ease;
}

.input-group input:focus {
  outline: none;
  border-color: var(--primary-color);
}

.submit-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: var(--primary-color);
  color: var(--light-text-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.submit-button:hover {
  background-color: var(--secondary-color);
  transform: scale(1.02);
}

.submit-button:disabled {
  background-color: var(--hover-background-color);
  cursor: not-allowed;
}

.error-message {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}

.google-login-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.google-login-button {
  background-color: #4285F4;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.google-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.google-login-button:hover {
  background-color: #357ae8;
}

.google-login-button:disabled {
  background-color: #d2d2d2;
  cursor: not-allowed;
}

/* ################################################################################# */
/* Location Data */

/* Page container with border */
.page-container {
  padding: 20px;
  border: 2px solid var(--secondary-color);
  /* Add border color */
  border-radius: 10px;
  background-color: var(--light-background-color);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 1200px;
}

.location-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

/* Equal height for all columns */
.location-col-lg-4,
.location-col-lg-8,
.location-col-md-4 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

/* Ensuring equal height for columns */
.location-card {
  flex-grow: 1;
  flex-basis: calc(33.33% - 20px);
  /* Adjusting for margin */
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* Makes sure the content takes up equal space */
  min-height: 300px;
  /* Sets a minimum height */
}

.location-card-no-data {
  visibility: hidden;
}

.location-basic-details {
  margin: 12px;
  padding: 0;
}

/* Headings for each section */
.card-heading {
  font-size: 1.4rem;
  /* Larger heading size */
  font-weight: bold;
  color: var(--dark-text-color);
  margin-bottom: 10px;
  text-align: center;
}

/* Separator line between heading and content */
.card-separator {
  background-color: var(--secondary-color);
  width: 100%;
  margin-top: -20px;
}

.response-time-card-separator {
  margin-top: 10px;
}

.io-other-details-separator {
  margin-top: 0px;
}

.rental-item-separator {
  margin-top: 0px;
}

.workflow-card-separator {
  margin-top: 0px;
}

.io-leads-count-separator {
  margin-top: 0px;
}

.io-leadDistribution-saparator {
  margin-top: 0px;
}

/* Social Links Styling */
.social-links-list {
  list-style: none;
  padding: 0;
  margin-top: -3px;
}

.social-links-list li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.social-links-list li .icon {
  margin-right: 10px;
}

.social-links-list li a {
  color: var(--primary-color);
  text-decoration: none;
}

.social-links-list li a:hover {
  text-decoration: underline;
}

/* Text styles for muted text */
.location-card .text-muted {
  margin-bottom: 10px;
  color: var(--muted-text-color);
  align-self: center;

}

/* Avatar in the logo card */
.avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 15px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* Utility class for map marker icon */
.gplace-icon {
  color: #CC5500;
  font-size: 1.5rem;
  display: block;
  margin-top: 10px;
}

.gplace-icon-link {
  align-self: center;
}

/* Ensure all columns maintain the same height */
.location-row {
  align-items: stretch;
}

.location-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* ############################################################################### */

/* Container for the response time */
.response-time-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.date-range-container {
  display: flex;
  align-items: center;
  /* Vertically align items */
  gap: 10px;
  /* Add spacing between elements */
}

.response-date-arrow {
  margin-top: 10px;
  font-size: 25px;
  /* Adjust arrow size */
  font-weight: bold;
  /* Make arrow bold */
}

.response-time-card input[type="date"] {
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 10px;
}

.response-time-card button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.response-time-card button:hover {
  background-color: #45a049;
}

.response-time-card .input-group {
  margin-bottom: 10px;
}

.response-display {
  margin-top: 10px;
}

.response-time-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 5px;
}

.response-time-group p {
  width: calc(50% - 10px);
  margin: 0;
  padding: 5px;
  border-radius: 5px;
}

.response-display strong {
  font-weight: bold;
  font-size: 16px;
}

/* Make datepicker inputs look aligned */
.date-inputs input {
  padding: 5px;
  font-size: 14px;
  margin-top: 5px;
  border-radius: 4px;
  border: 1px solid var(--secondary-color);
  /* Adjust border color to match theme */
}

/* Ensure date input text aligns properly */
.date-inputs label {
  font-size: 14px;
  color: var(--dark-text-color);
  /* Adjust label color */
  margin-bottom: 2px;
}

/* Adjust padding for better alignment */
.date-inputs input[type="date"] {
  padding: 8px;
}

/* ############################################################################### */
/* IoLeads count */

.ioLeadsCount-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ioLeadsCount-display-data {
  margin-right: 20px;
  margin-top: 15px;
  text-align: left;
  font-size: small;
}

.ioLeadsCount-pie-chart {
  position: relative;
  width: 200px;
}

.ioLeadsCount-conversion-rate {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.ioLeadsCount-conversion-rate p {
  font-size: small;
}

.ioLeadsCount-data-objects {
  margin-bottom: 10px;
}

/* ############################################################################### */

/* IoOtherDetails.css */

.io-other-details-content {
  overflow-y: auto;
  padding-right: 10px;
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
}

.io-other-details-content ul {
  list-style: none;
  padding: 0;
}

.io-other-details-content li {
  margin-bottom: 10px;
}

.io-other-details-content strong {
  font-weight: 600;
  color: #333;
}

/* Optional: Add styles for the scrollbar */
.io-other-details-content::-webkit-scrollbar {
  width: 8px;
}

.io-other-details-content::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.io-other-details-content::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* ############################################################################### */

.date-selector-container {
  display: flex;
  align-items: center;
}

.date-input {
  border: 1px solid #ddd;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 14px;
  margin-right: 8px;
}

.date-arrow {
  margin-right: 8px;
  font-size: 18px;
  color: #888;
}

.apply-button {
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.apply-button:hover {
  background-color: #45a049;
}

.apply-button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

/* ############################################################################## */

.ioLeadDistribution-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
}

.ioLeadDistribution-chart {
  position: relative;
  width: 200px;
}

.ioLeadsDistribution-data {
  margin-right: 20px;
  margin-top: 15px;
  text-align: left;
  font-size: small;
}

.ioLeadDistribution-dataObjects {
  margin-bottom: 10px;
}

.ioLeadDistribution-dataObjects:nth-child(1)::before,
.ioLeadDistribution-dataObjects:nth-child(2)::before,
.ioLeadDistribution-dataObjects:nth-child(3)::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 5px;
  vertical-align: middle;
}

/* Specific colors for each point */
.ioLeadDistribution-dataObjects:nth-child(1)::before {
  background-color: #36A2EB;
  /* Color for Web Leads */
}

.ioLeadDistribution-dataObjects:nth-child(2)::before {
  background-color: #4BC0C0;
  /* Color for Web Events */
}

.ioLeadDistribution-dataObjects:nth-child(3)::before {
  background-color: #FFCE56;
  /* Color for Web Conversion */
}


/* ############################################################################## */

/* Content Styling */
.rental-card-content {
  display: flex;
  flex-direction: column;
}

/* Rental Summary Styling */
.rental-summary {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 5px
}

/* Table Styling */
.popular-items-table table {
  width: 100%;
  border-collapse: collapse;
  overflow-y: auto;
  max-height: 235px;
}

.popular-items-table thead {
  background-color: var(--primary-color);
  color: #fff;
}

.popular-items-table th,
.popular-items-table td {
  padding: 5px;
  text-align: left;
  border-bottom: 1px solid var(--secondary-color);
  font-size: 0.9em;
}

/* Responsive Table for Overflow */
.popular-items-table {
  overflow-y: auto;
  max-height: 235px;
  min-height: 235px;
}

.popular-items-table tbody tr:hover {
  background-color: var(--light-background-color);
}

/* Scrollbar Styling */
.popular-items-table::-webkit-scrollbar {
  width: 8px;
}

.popular-items-table::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 4px;
}

/* ############################################################################## */

.workflow-card-content {
  display: flex;
  flex-direction: column;
}

.workflow-table table {
  width: 100%;
  border-collapse: collapse;
  overflow-y: auto;
  max-height: 235px;
}

.workflow-table thead {
  background-color: var(--primary-color);
  color: #fff;
}

.workflow-table th,
.workflow-table td {
  padding: 5px;
  text-align: left;
  border-bottom: 1px solid var(--secondary-color);
  font-size: 0.9em;
}

.workflow-table {
  overflow-y: auto;
  max-height: 235px;
  min-height: 235px;
}

.workflow-table tbody tr:hover {
  background-color: var(--light-background-color);
}

/* Scrollbar Styling */
.workflow-table::-webkit-scrollbar {
  width: 8px;
}

.workflow-table::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 4px;
}

.workflow-card-summary {
  margin-bottom: 5px;
}

/* ############################################################################## */

.social-media-conected {
  margin-top: -83px
}

/* ############################################################################## */
/* Billing data card */

.billing-card-tabs {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.billing-tab {
  padding: 5px 10px;
  margin-right: 5px;
  background-color: transparent;
  /* Transparent to blend with background */
  color: #555;
  /* Subtle text color */
  border: 1px solid transparent;
  /* Border only for spacing, invisible initially */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.billing-tab.active {
  background-color: #f4f4f4;
  /* Light gray background for active state */
  color: #222;
  /* Darker text on active for contrast */
  border: 1px solid #ddd;
  /* Subtle border to highlight active state */
}

.billing-tab:hover {
  background-color: #f8f8f8;
  /* Slight hover effect */
  color: #333;
  /* Darker text on hover */
  border: 1px solid #ddd;
  /* Subtle border on hover */
}

.dropdown-container {
  margin-left: auto;
}

.filter-dropdown {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
}

.billing-card-content {
  display: flex;
  flex-direction: column;
}

.billing-table table {
  width: 100%;
  border-collapse: collapse;
  overflow-y: auto;
  max-height: 235px;
}

.billing-table thead {
  background-color: var(--primary-color);
  color: #fff;
}

.billing-table th,
.billing-table td {
  padding: 5px;
  text-align: left;
  border-bottom: 1px solid var(--secondary-color);
  font-size: 0.9em;
}

.billing-table {
  overflow-y: auto;
  max-height: 235px;
  min-height: 235px;
}

.billing-table tbody tr:hover {
  background-color: var(--light-background-color);
}

/* Scrollbar Styling */
.billing-table::-webkit-scrollbar {
  width: 8px;
}

.billing-table::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 4px;
}

/* ############################################################################ */
/* Ads data card */

.ads-card-content {
  display: flex;
  flex-direction: column;
}

.ads-table table {
  width: 100%;
  border-collapse: collapse;
  overflow-y: auto;
  max-height: 235px;
}

.ads-table thead {
  background-color: var(--primary-color);
  color: #fff;
}

.ads-table th,
.ads-table td {
  padding: 5px;
  text-align: left;
  border-bottom: 1px solid var(--secondary-color);
  font-size: 0.9em;
}

.ads-table {
  overflow-y: auto;
  max-height: 235px;
  min-height: 235px;
}

.ads-table tbody tr:hover {
  background-color: var(--light-background-color);
}

/* Scrollbar Styling */
.ads-table::-webkit-scrollbar {
  width: 8px;
}

.ads-table::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 4px;
}

/* ################################################################################ */
/* Styles for the Advanced Filters */

/* General Container Styles */
.advanced-filters-container {
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  font-family: Arial, sans-serif;
}

/* Header Styles */
.advanced-filters-container h3 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

/* Filter Input Styles */
.filter-input-container {
  padding: 15px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.filter-row {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 10px;
}

.filter-item {
  flex: 1 1 20%;
  display: flex;
  flex-direction: column;
}

.filter-item label {
  font-weight: bold;
  margin-bottom: 5px;
  color: #444;
}


.filter-item input,
.filter-item select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  background: #fff;
}

.toggle-text {
  cursor: pointer;
  color: #007bff;
  font-weight: bold;
  text-align: right;
  margin: 5px 0;
}

.toggle-text:hover {
  text-decoration: underline;
}

/* Advanced Filters Section */
.advanced-filters {
  padding: 10px;
  background: #eef2f7;
  border-radius: 4px;
  margin-top: 5px;
}

.filter-item input[type="number"] {
  max-width: calc(50% - 5px);
  margin-right: 5px;
}

.filter-item input[type="date"] {
  padding: 6px;
}


/* Action Buttons */
.filter-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.filter-actions button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  color: white;
}

/* Apply Filters Button */
.filter-actions button:first-child {
  background-color: #28a745; /* Green */
}

/* Clear Filters Button */
.filter-actions button:nth-child(2) {
  background-color: #dc3545; /* Red */
}

/* Save View Button */
.filter-actions button:nth-child(3) {
  background-color: #007bff; /* Blue */
}
/* Save As New */
.filter-actions button:nth-child(4) {
  background-color: #ffa900; /* Blue */
}

.filter-actions button:hover {
  opacity: 0.9;
}

/* Filter Display Styles */
.filter-display-container {
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  padding: 20px;
  max-width: 800px;
}

.filter-display-container .error-message {
  color: #dc3545;
  font-weight: bold;
  text-align: center;
}

.results-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}


/* Responsive Design */
@media (max-width: 768px) {
  .filter-row {
      flex-direction: column;
  }

  .filter-item {
      flex: 1 1 100%;
  }
}

/* ######################################################### */

.views-dropdown-container {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
}

#viewsDropdown {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

#viewsDropdown:hover {
  cursor: pointer;
}

