/* variables.css */

:root {
    --primary-color: #4A90E2;
    --secondary-color: #8E44AD;
    --accent-color: #FF0080;
    --light-background-color: #f9f9f9;
    --dark-text-color: #333;
    --light-text-color: #ffffff;
    --hover-background-color: rgba(255, 255, 255, 0.3);
    --hover-icon-color: rgba(255, 255, 255, 0.7);
}
